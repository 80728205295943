import { Swiper, SwiperSlide } from 'swiper/react';
import FeaturedProductSingle from './FeaturedProductSingle';
import 'swiper/css';
import 'swiper/css/effect-fade';

export default (props) => {
    const { data } = props
    return (
        <Swiper
            style={{ zIndex: 0 }}
            breakpoints={{
                '@0.00': {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                '@0.75': {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                '@1.00': {
                    slidesPerView: 4,
                    spaceBetween: 50,
                },
                '@1.25': {
                    slidesPerView: 4,
                    spaceBetween: 50,
                },
                '@1.5': {
                    slidesPerView: 5,
                    spaceBetween: 50,
                },
            }}
        >
            {data?.map((el, index) => {
                return <SwiperSlide key={index}>
                    <FeaturedProductSingle
                        key={index}
                        id={el.id}
                        title={el.title}
                        price={el.price}
                        img={el.url}
                        sale={el.sale}
                        options={el.options}
                        hot={el.hot}
                    />
                </SwiperSlide>
            })}
        </Swiper>
    );
};