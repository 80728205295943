import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import axios from "axios";
import { url } from "../url";
import AlertPopUp from "./AlertPopUp";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

export default function MessageReply(props) {
	const { open, data, setSelectedMessage, currentUser, activeTab } = props;
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [openAlert, setOpenAlert] = useState(false);
	const [color, setColor] = useState("");
	const [message, setMessage] = useState("");
	const [isReply, setIsReply] = useState(false);
	const [openConFirmationPopup, setOpenConfirmationPopup] = useState(false);

	const handleClose = () => {
		setSelectedMessage(null);
		setConfirmDelete(false);
	};
	const handleAlert = (txt, color) => {
		setOpenAlert(true);
		setMessage(txt);
		setColor(color);
	};
	const deleteMessage = (id) => {
		console.log(id);
		axios.delete(url + `/delete_message/${id}`).then((res) => {
			if (res.data.message !== "delete faild") {
				handleAlert(res.data.message, "success");
			} else {
				handleAlert(res.data.message, "error");
			}
		});
		setTimeout(() => {
			window.location.reload();
		}, 2000);
	};
	const [textValue, setTextValue] = useState("");

	const handleTextareaChange = (e) => {
		setTextValue(e.target.value);
	};
	const submitResponse = () => {
		axios
			.post(url + "/reply_email", {
				send_to: data.send_from,
				send_from: data.send_to,
				message: textValue,
				orderNumber: data.orderNumber,
				name: currentUser.data.firstName,
				currentMessageId: data.id,
			})
			.then((response) => {
				if (response.data.message === "Email sent") {
					console.log(response);
					setOpenConfirmationPopup(true);
					setTimeout(() => {
						handleAlert(response.data.message, "success");
						window.location.reload();
					}, 1000);
				} else {
					handleAlert("Message failed!", "error");
				}
			});
	};
	return (
		<div>
			<AlertPopUp
				open={openAlert}
				setOpen={setOpenAlert}
				message={message}
				handleClick={handleAlert}
				color={color}
			/>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						From: {data?.name}
					</Typography>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						Subject: {"Question About # " + data?.orderNumber?.toUpperCase()}
					</Typography>
					<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
						Message:
						<div>{data?.message}</div>
					</Typography>
					{isReply ? (
						<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
							<TextareaAutosize
								minRows={10}
								maxRows={20}
								placeholder="Type here"
								value={textValue}
								onChange={handleTextareaChange}
								style={{ width: "100%" }}
							/>
						</Typography>
					) : (
						<></>
					)}

					{!isReply ? (
						<Button
							variant="contained"
							style={{ marginRight: "10px" }}
							onClick={() => setIsReply(true)}
							disabled={activeTab === "sent"}
						>
							Reply
						</Button>
					) : (
						<Button
							color="success"
							variant="contained"
							style={{ marginRight: "10px" }}
							onClick={submitResponse}
						>
							Send
						</Button>
					)}

					{currentUser.data.role === "admin" ? (
						<span>
							<Button
								color="warning"
								variant="contained"
								style={{ marginRight: "10px" }}
								hidden={confirmDelete}
								onClick={() => setConfirmDelete(true)}
							>
								Delete
							</Button>
							<Button
								color="error"
								variant="contained"
								style={{ marginRight: "10px" }}
								hidden={!confirmDelete}
								onClick={() => {
									deleteMessage(data.id);
								}}
							>
								CONFIRM
							</Button>
						</span>
					) : (
						<></>
					)}
				</Box>
			</Modal>
		</div>
	);
}
