import { Paper, Box, Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { DashContext } from '../DashContext';
import CircularProgress from '@mui/material/CircularProgress';
import  PieChart  from './PieChart'
import Table from './Table'


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    // height: 60,
    lineHeight: '60px',
}));

const darkTheme = createTheme({ palette: { mode: 'dark' } });
// const lightTheme = createTheme({ palette: { mode: 'light' } });



export default function Dashboard(props) {
    const {
        getIncome,
        income,
        isLoading

    } = useContext(DashContext)

    const [data, setData] = useState([])


    const loadIncome = () => {
        getIncome()
        setData(income)
    }

    const formatLabel = (label) => label.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());


    useEffect(() => {
        loadIncome()
    }, [])


    return (
        <div className='container mt-5' style={{ width: '100%' }} >


            <Grid container spacing={2} className='d-flex' style={{ justifyContent: 'center' }}>
                <Grid item xs={12}>
                    <ThemeProvider theme={darkTheme}>
                        <Box
                            sx={{
                                p: 2,
                                borderRadius: 2,
                                bgcolor: 'background.default',
                                display: 'grid',
                                gridTemplateColumns: { md: '1fr 1fr' },
                                gap: 2,
                            }}
                        >

                            {
                                // Object.entries(data).map(([key, value]) => (
                                //     typeof value === 'object' && value !== null ? (
                                //         Object.entries(value).map(([subKey, subValue]) => (
                                //             <Item elevation={3} sx={{ p: 2 }}>
                                //                 <h3>{formatLabel(subKey.toUpperCase())}</h3>
                                //                 {
                                //                     !isLoading ?
                                //                         <h1>{subValue}</h1>
                                //                         : <CircularProgress color='success' />
                                //                 }
                                //             </Item>
                                //         ))
                                //     ) : <div></div>
                                // ))

                            }
                            <Item elevation={3} sx={{ p: 2 }}>
                                <h3>TOTAL SALE</h3>
                                {
                                    !isLoading ?
                                        <h1>${income.income?.total_amount}</h1>
                                        : <CircularProgress color='success' />
                                }
                            </Item>
                            <Item elevation={3} sx={{ p: 2 }}>
                                <h3>ITEM SOLD</h3>
                                {
                                    !isLoading ?
                                        <h1>{income.income?.total_qt}</h1>
                                        : <CircularProgress color='success' />
                                }
                            </Item>
                            <Item elevation={3} sx={{ p: 2 }}>
                                <h3>COMPLETED ORDERS</h3>
                                {
                                    !isLoading ?
                                        <h1>{income.income?.total_orders}</h1>
                                        : <CircularProgress color='success' />
                                }
                            </Item>
                            <Item elevation={3} sx={{ p: 2 }}>
                                <h3>BEST SELLING PRODUCT</h3>
                                {
                                    !isLoading ?
                                        <h3>{income.bestSellingItem?.title} x {income?.bestSellingItem?.total_quantity_sold}</h3>
                                        : <CircularProgress color='success' />
                                }
                            </Item>
                         
                            {

                                // {[0, 1, 2, 3, 4, 6, 8, 12, 16, 24].map((elevation) => (
                                //     <Item key={elevation} elevation={elevation}>
                                //         {`elevation=${elevation}`}
                                //     </Item>
                                // ))}
                            }
                        </Box>
                        <Box
                            sx={{
                                p: 2,
                                borderRadius: 2,
                                bgcolor: 'background.default',
                                display: 'grid',
                                // gridTemplateColumns: { md: '1fr 1fr' },
                                gap: 2,
                            }}
                        >
                            <Table data={income.userPoints}/>
                        </Box>
                    </ThemeProvider>
                </Grid>
            </Grid>
        </div>
    )
}
