import { createContext, useState, useEffect } from "react";

export const LanguageContext = createContext();
export function LanguageContextProvider({ children }) {
	const [isEn, setIsEn] = useState(false);
	const changeLanguage = () => {
		setIsEn(!isEn);
	};
	return (
		<LanguageContext.Provider value={{ isEn, changeLanguage }}>
			{children}
		</LanguageContext.Provider>
	);
}
