import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HistoryDetail from "./HistoryDetail";
import AnimationSharpIcon from "@mui/icons-material/AnimationSharp";
import MovingIcon from '@mui/icons-material/Moving';
import { useMediaQuery } from '@mui/material';



export default function HistoryAccordion(props) {
	const { data, currentUser, getOrderHistory } = props;
	const isSmallScreen = useMediaQuery('(max-width:600px)'); // Hook to check screen width

	return (
		<div style={{ margin: "15px 0" }}>
			<Accordion
				elevation={4}
				sx={{
					backgroundColor: "rgba(23, 20, 20, 0.85)",
					color: "white",
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography sx={{ fontWeight: "500", marginRight: "20px" }}>
						{data.createdAt.split(" ")[0]}
					</Typography>
					<Typography sx={{ fontWeight: "500" }}>
						{data.orderNumber.toUpperCase()}
					</Typography>
					{
						data.items[0].status === 'completed' ?
							<Typography sx={{ fontWeight: "500", width: isSmallScreen ? '145px' : '200px', float: 'right' }}>
								<MovingIcon sx={{ color: "gold", margin: '0 10px' }} />
								{parseInt(data.items[0].subtotal) + ' points'}
							</Typography> : <></>
					}
				</AccordionSummary>
				<AccordionDetails>
					<div>
						{
							<HistoryDetail
								data={data}
								currentUser={currentUser}
								getOrderHistory={getOrderHistory}
							/>
						}
					</div>
				</AccordionDetails>
			</Accordion>
		</div>
	);
}
