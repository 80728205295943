import { React, useEffect, useContext, useState } from "react";
import { Container } from "react-bootstrap";
import { ProductContext } from "../ProductContext";
import { LanguageContext } from "../controller/LanguageContext";
import Swipe from './SwiperNew';

export default function FeaturedProductsList() {
	const { featureProducts, getProducts, getFeatureProducts } = useContext(ProductContext);
	const { isEn } = useContext(LanguageContext);
	const lists = ['On Sale', 'Hot'];

	useEffect(() => {
		const fetchData = async () => {
			await getFeatureProducts(1);
		};
		fetchData();
	}, []);

	return (
		<Container>
			{lists.map((el, index) => {
				return (
					<div style={{ borderBottom: "1px solid black", marginBottom: "40px" }} key={index}>
						<p style={{ textAlign: "left", color: "white", fontSize: "20px" }}>
							{!isEn ? (<strong>{el}</strong>) : (<strong>{el === 'On Sale' ? '特價商品' : '熱門商品'}</strong>)}
						</p>
						{el === 'On Sale' ? <Swipe data={featureProducts.sale} /> : <Swipe data={featureProducts.hot} />}
					</div>
				);
			})}
		</Container>
	);
}
