import Dropdown from "react-bootstrap/Dropdown";

function Filter(props) {
	const { received, sent } = props;
	return (
		<Dropdown>
			<Dropdown.Toggle variant="success" id="message-dropdown">
				FILTER
			</Dropdown.Toggle>

			<Dropdown.Menu>
				<Dropdown.Item onClick={sent}>RECEIVED</Dropdown.Item>
				<Dropdown.Item onClick={received}>SENT</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
}

export default Filter;
