import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { url } from "./url";
import AlertPopUp from "./components/AlertPopUp";
export const AuthContext = createContext();

export function AuthContextProvider({ children }) {
	const navigate = useNavigate();

	const [token, settoken] = useState();
	const [errorMessage, setErrorMessage] = useState("");
	// const [isLogin, setIsLogin] = useState(false)

	const login = async (email, password) => {
		try {
			const response = await axios.post(url + "/login", {
				email: email,
				password: password,
			});

			if (response.data.error) {
				setErrorMessage(response.data.error);
			} else {
				settoken(response.data); // Assuming `setToken` is a valid state setter
				setErrorMessage("");
				navigate("/"); // Assuming `navigate` is from `react-router-dom`
				// Optionally reload the page if necessary
				// window.location.reload();
			}
		} catch (error) {
			console.error("Error during login:", error);
			setErrorMessage("An error occurred during login. Please try again.");
		}
	};

	const signUp = async (
		firstName,
		lastName,
		email,
		password,
		DOB,
		phone,
		address,
		city,
		state,
		zipcode,
		referralCode,
	) => {
		if (
			email.length > 0 &&
			password.length > 0 &&
			firstName.length > 0 &&
			lastName.length > 0 &&
			DOB.length > 0 &&
			phone.length > 0 &&
			address.length > 0 &&
			city.length > 0 &&
			state.length > 0 &&
			zipcode.length > 0
		) {
			await axios
				.post(url + "/signup", {
					firstName: firstName,
					lastName: lastName,
					email: email,
					password: password,
					DOB: DOB,
					phone: phone,
					address: address,
					city: city,
					state: state,
					zipcode: zipcode,
					referralCode: referralCode.toUpperCase(),
				})
				.then((response) => {
					if (response.data.error) {
						setErrorMessage(response.data.error);
					} else {
						login(email, password);
					}
				});
		} else {
			setErrorMessage("All basic bios are required!");
		}
	};

	const logout = () => {
		axios.post(url + `/logout`).then((response) => {
			// setIsLogin(false)
			settoken("");
			// setUser({})
			setErrorMessage("");
			window.location.reload();
		});
	};
	return (
		<AuthContext.Provider
			value={{
				token,
				login,
				errorMessage,
				signUp,
				// user,
				// isLogin,
				logout,
				setErrorMessage,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export default AuthContextProvider;
