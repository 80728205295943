import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import MovingIcon from '@mui/icons-material/Moving';
import { useCurrentUser } from "../service/authService";
import { useProductById } from "../service/productService";

import { ProductContext } from "../ProductContext";
import AlertPopUp from "../components/AlertPopUp";
import EditProduct from "./EditProduct";
import PopOver from "./PopOver.js";
import AnimationSharpIcon from "@mui/icons-material/AnimationSharp";
import saleIcon from "../images/saleIcon.png";
import fireIcon from '../images/fire_icon.png'
import hotAndSale from '../images/hot_and_sale.png'
import LoadingBar from "./LoadingBar.js";


export default function FeaturedProductSingle(props) {
	const { id, img, title, price, sale, description, points_needed, hot } = props;
	const {
		isLoading: isCurrentUserLoading,
		data: currentUser,
	} = useCurrentUser();

	const { additionalImages, setAdditionalImages, getAdditionalImages, deleteConfirmation, deleteProduct, getProductById } = useContext(ProductContext);
	const [open, setOpen] = useState(false);
	const [color, setColor] = useState("");
	const [message, setMessage] = useState("");
	const [show, setShow] = useState(false);
	const [confirmation, setConfirmation] = useState(false);
	const [showPopOver, setShowPopOver] = useState(false);
	const [productData, setProductData] = useState()

	const showEdit = () => {
		setShow(true);
		loadProduct(id)
	}
	const hideEdit = () => setShow(false);

	const handleDelete = () => {
		setShowPopOver(true);
	};

	const handleClick = (txt, color) => {
		setOpen(true);
		setMessage(txt);
		setColor(color);
	};

	const confirmDelete = () => {
		try {
			deleteProduct(id);
			handleClick("Product Deleted, redirect in 2 seconds !", "success");
		} catch (error) {
			handleClick("Something went wrong !", "error");
		}
		setTimeout(() => {
			window.location.reload();
		}, 2000);
	};

	const loadProduct = async (id) => {
		const data = await getProductById(id)
		setProductData(data)
	}

	useEffect(() => {

	}, [productData]);

	if (!currentUser) {
		return <LoadingBar />;
	}
	return (
		<div className="card fadeIn">
			<EditProduct
				show={show}
				hideEdit={hideEdit}
				productId={id}
				productData={productData}
				getAdditionalImages={getAdditionalImages} // Pass function as a prop
				additionalImages={additionalImages} // Pass state as a prop
			/>
			<Card style={{ borderRadius: 0, overflow: "auto" }}>
				<Link
					to={`/shop/` + id}
					style={{ textDecoration: "none", color: "white" }}
				>
					<CardMedia
						sx={{ height: 250 }}
						component="img"
						image={img}
						title="green iguana"
					/>
				</Link>

				<CardContent
					sx={{
						textAlign: "left !important",
						backgroundColor: "black",
						overflow: "auto",
					}}
				>
					<Typography
						gutterBottom
						variant="p"
						component="div"
						sx={{
							overflow: "hidden",
							textOverflow: "ellipsis !important",
							width: "100%",
							whiteSpace: "nowrap",
							fontWeight: "bold",
							color: "white",
						}}
					>
						{title.toUpperCase()}
					</Typography>
					<Stack direction="row" sx={{ justifyContent: "space-between" }}>
						<Typography variant="body2" color="white">
							${price}
						</Typography>
						<Typography variant="body2" color="white">
							<AnimationSharpIcon sx={{ color: "gold" }} />
							{points_needed}
						</Typography>
					</Stack>

					{sale === 1 && hot === 1 ? (
						// Both sale and hot are true
						<div>
							<span style={{ position: 'absolute', top: '-25px', right: '-25px', borderRadius: '50%' }}>
								<img src={saleIcon} style={{ width: '70px', height: 'auto' }} />
							</span>
							<span style={{ position: 'absolute', top: '5px', right: '-24px', borderRadius: '50%' }}>
								<img src={fireIcon} style={{ width: '60px', height: 'auto', transform: 'rotate(20deg)' }} />
							</span>
						</div>

					) : sale === 1 ? (
						// Only sale is true
						<span style={{ position: 'absolute', top: '-30px', right: '-30px', borderRadius: '50%' }}>
							<img src={saleIcon} style={{ width: '70px', height: 'auto' }} />
						</span>
					) : hot === 1 ? (
						// Only hot is true
						<span style={{ position: 'absolute', top: '-21px', right: '-25px', borderRadius: '50%' }}>
							<img src={fireIcon} style={{ width: '60px', height: 'auto', transform: 'rotate(15deg)' }} />
						</span>
					) : (
						// Neither sale nor hot are true (optional)
						<div></div>
					)}



					{currentUser.data?.role === "admin" ? (
						<span>
							<Button
								variant="contained"
								sx={{
									background: "#094100",
									fontSize: "10px",
									width: 1,
									marginTop: "15px;",
								}}
								onClick={showEdit}
							>
								Edit
							</Button>
							<Button
								variant="contained"
								sx={{
									background: "red",
									fontSize: "10px",
									width: 1,
									marginTop: "15px;",
								}}
								onClick={handleDelete}
							>
								Delete
							</Button>
						</span>
					) : (
						<p></p>
					)}
				</CardContent>
				<AlertPopUp
					open={open}
					setOpen={setOpen}
					message={message}
					handleClick={handleClick}
					color={color}
				/>
			</Card>
			<PopOver
				open={showPopOver}
				setOpen={setShowPopOver}
				title="Confirm Delete ?"
				description="Once confirmed it will be deleted forever..."
				action={confirmDelete}
			/>
		</div>
	);
}
