import React, { useContext, useEffect } from "react";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { ProductContext } from "../ProductContext";

const StyledBadge = styled(Badge)(({ theme }) => ({
	"& .MuiBadge-badge": {
		right: -3,
		top: 13,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: "0 4px",
	},
}));

export default function CartBadge(props) {
	const { itemCount } = props;

	return (
		<IconButton aria-label="cart" style={{ color: "white" }}>
			<StyledBadge badgeContent={itemCount} color="secondary">
				<ShoppingCartIcon />
			</StyledBadge>
		</IconButton>
	);
}
