import * as React from 'react';
import Switch from '@mui/material/Switch';

export default function ToggleSwitch({ onToggle }) {
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        const newChecked = event.target.checked;
        setChecked(newChecked);
        onToggle(newChecked); // Call the parent callback with the new state
    };

    return (
        <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    );
}