import * as React from "react";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

const StyledBadge = styled(Badge)(({ theme }) => ({
	"& .MuiBadge-badge": {
		right: -3,
		top: 13,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: "0 4px",
	},
}));
export default function MailBadge(props) {
	const { badgeContent } = props;

	return (
		<IconButton aria-label="cart" style={{ color: "white" }}>
			<StyledBadge badgeContent={badgeContent} color="secondary">
				<MailIcon />
			</StyledBadge>
		</IconButton>
	);
}
