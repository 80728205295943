import Form from "react-bootstrap/Form";

function FormSelect(props) {
	const { data, setSelectedMethod } = props;
	return (
		<>
			<Form.Select
				size="sm"
				style={{ width: "110px", float: "right" }}
				onChange={(e) => {
					setSelectedMethod(JSON.parse(e.target.value));
				}}
			>
				{data.map((el, index) => {
					return (
						<option key={index} value={JSON.stringify(el)}>
							{el.label.toUpperCase()}
						</option>
					);
				})}
			</Form.Select>
		</>
	);
}

export default FormSelect;
