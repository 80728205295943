import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from 'chart.js';
import { url } from '../url';
import axios from 'axios';

ChartJS.register(Tooltip, Legend, ArcElement);

export default function PieChart(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [orderHistory, setOrderHistory] = useState([]);
    const [dataSet, setDataSet] = useState([]);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Sold',
                data: [],
                backgroundColor: [],
                hoverOffset: 50
            },
        ],
    });

    const generateRandomColor = () => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgb(${r}, ${g}, ${b})`;
    };

    const generateColorArray = (numColors) => {
        return Array.from({ length: numColors }, () => generateRandomColor());
    };


    const getOrderHistory = async () => {
        setIsLoading(true); // Start loading
        try {
            const response = await axios.get(url + '/order_history');
            setOrderHistory(response.data);
        } catch (error) {
            console.error('Error fetching order history:', error);
        } finally {
            setIsLoading(false); // End loading
        }
        console.log(orderHistory)
    };

    useEffect(() => {
        getOrderHistory();
    }, []);

    useEffect(() => {
        if (orderHistory.length > 0) {
            // Flatten all items from each object into a single array
            const elementArray = orderHistory.flatMap((obj) => obj.items);

            // Set the extracted items to dataSet
            setDataSet(elementArray);

            const productQuantity = elementArray.reduce((acc, item) => {
                const { product_id, qt } = item; // Destructure product_id and qt
                acc[product_id] = (acc[product_id] || 0) + qt; // Sum qt for each product_id
                return acc;
            }, {});


            // Extract titles and their count
            const titleCount = elementArray.reduce((acc, item) => {
                acc[item.title] = (acc[item.title] || 0) + 1; // Count occurrences of each title
                return acc;
            }, {});

            // Prepare labels and data for chart
            const labels = Object.keys(titleCount); // Get all unique titles
            const data = Object.values(productQuantity); // Get the count of each title

            //generate color for each item
            const colors = generateColorArray(labels.length);

            // Update chartData with dynamic labels and data
            setChartData((prevChartData) => ({
                ...prevChartData,
                labels,
                datasets: [
                    {
                        ...prevChartData.datasets[0],
                        data,
                        backgroundColor: colors,

                    },
                ],
            }));
        }
    }, [orderHistory]); // Run this effect when orderHistory changes

    const chartOptions = {
        responsive: true, // Make chart responsive
        plugins: {
            legend: {
                position: 'top', // Position the legend (top, bottom, left, right)
                maintainAspectRatio: false,
                labels: {
                    boxWidth: 10, // Width of the color box next to each label
                    font: {
                        size: window.innerWidth < 600 ? 10 : 14, // Font size for legend labels
                        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        style: 'italic',
                        weight: 'bold',
                    },
                    color: 'white', // Color of legend text
                    padding: 12, // Padding around legend text
                },
            },
            tooltip: {
                enabled: true, // Enable tooltips
                backgroundColor: 'rgba(0, 0, 0, 0.7)', // Background color of tooltip
                bodyFont: {
                    size: 12, // Font size for tooltip text
                },
                borderColor: '#ddd', // Border color of the tooltip
                borderWidth: 1,
                padding: 10, // Padding inside the tooltip
                boxPadding: 5, // Padding outside the tooltip box
            },
        },
    };


    return (
        <div className="chart-container">
            <Pie data={chartData} options={chartOptions} />
        </div>

    );
}
