import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // For getting the token
import axios from 'axios';
import { url } from '../url'
import ConfirmationPopup from './ConfirmationPopup';

function ResetPasswordPage() {
    const { resetToken } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [openConFirmationPopup, setOpenConfirmationPopup] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (newPassword !== confirmPassword) {
            return setMessage('Passwords do not match');
        }

        try {
            const response = await axios.post(url + '/update-password', {
                resetToken,
                newPassword
            });
            setMessage(response.data.message);
            if (response.data.message !== 'Reset link has expired') {
                setOpenConfirmationPopup(true);
                setTimeout(() => {
                    navigate("/loginForm");
                }, 2100);
            }
        } catch (error) {
            setMessage('Error resetting password. Please try again.');
        }
    };

    return (
        <div className=' mt-5'>
            <ConfirmationPopup
                openConFirmationPopup={openConFirmationPopup}
                setOpenConfirmationPopup={setOpenConfirmationPopup}
            />
            {message && <p style={{ color: 'red' }}>{message}</p>}

            <form onSubmit={handleSubmit} className='Auth-form reset-password-page mt-5'>
                <div
                    className="form-group mt-3 whiteText col-8"
                    style={{ margin: "auto" }}
                >
                    <label>New Password</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="New Password"
                    />
                </div>
                <div
                    className="form-group mt-3 whiteText col-8"
                    style={{ margin: "auto" }}
                >
                    <label>Confirm Password</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm Password"
                    />
                </div>
                <div className="d-grid gap-2 mt-5">
                    <button
                        style={{
                            backgroundColor: "#094100",
                            border: "none",
                            width: "200px",
                            margin: "auto",
                        }}
                        type="submit"
                        className="btn btn-primary"
                    >
                        Reset Password
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ResetPasswordPage;
