import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { React, useEffect, useState } from "react";
import Navigation from "./Navigation";
import { Route, Routes } from "react-router-dom";
import axios from "axios";
import Home from "./components/Home";
import Shop from "./components/Shop";
import Footer from "./Footer";
import Contact from "./components/Contact";
import ProductDetail from "./components/ProductDetail";
import CreateProduct from "./components/CreateProduct";
import CreatePromoCode from "./components/CreatePromoCode";
import ProductList from "./ProductList";
import { AuthContextProvider } from "./AuthContext";
import { ProductContextProvider } from "./ProductContext";
import { DashContextProvider } from "./DashContext";

import LoginForm from "./components/LoginForm";
import { useCurrentUser } from "./service/authService";
import ProtectedRoute from "./ProtectedRoute";
import Profile from "./components/Profile";
import OrderSubmitPage from "./components/OrderSubmitPage";
import CheckOut from "./components/CheckOut";
import InfiniteLooper from "./InfiniteLooper";
import { LanguageContextProvider } from "./controller/LanguageContext";
import MessagesPage from "./components/MessagesPage";
import Disclaimer from "./components/Disclaimer";
import ResetPassword from "./components/ResetPassword";
import ResetPasswordPage from "./components/ResetPasswordPage"
import Report from "./components/Report";
import LoadingBar from "./components/LoadingBar";
import Dashboard from "./components/Dashboard";

function App() {
	axios.defaults.withCredentials = true;
	const { data, refetch, isLoading } = useCurrentUser();
	const [showPopup, setShowPopup] = useState(true);

	const handleClosePopup = () => {
		setShowPopup(false);
		// Save the timestamp when the popup was last closed in local storage
		localStorage.setItem("lastPopupClosed", Date.now());
	};

	useEffect(() => {
		refetch();
		if (!localStorage.getItem("h3")) {
			localStorage.setItem("h3", JSON.stringify([]));
		}
	}, []);

	useEffect(() => {
		const lastPopupClosedTimestamp = localStorage.getItem("lastPopupClosed");
		const oneHour = 3600000 * 24; // 1 hour in milliseconds

		// If the popup was never closed or last closed more than an hour ago
		if (
			!lastPopupClosedTimestamp ||
			Date.now() - parseInt(lastPopupClosedTimestamp) > oneHour
		) {
			setShowPopup(true);
		} else {
			setShowPopup(false);
		}

		const interval = setInterval(() => {
			setShowPopup(true);
		}, oneHour);

		return () => {
			clearInterval(interval);
		};
	}, []);

	if (isLoading) {
		return <LoadingBar />;
	}

	return (
		<div className="App">
			<LanguageContextProvider>
				<AuthContextProvider>
					<DashContextProvider>
						<ProductContextProvider>
							<Navigation currentUser={data} />
							{showPopup && <Disclaimer onClose={handleClosePopup} />}
							<div className="bannerContainer">
								<p className="bannerText">
									Every $1 Spent = 1 Points To Be Redeemed For Future Orders &
									Gifts
								</p>
								<p className="bannerText">
									Refer A Customer & Receive 100 Points To Your Account{" "}
								</p>
								{/* <InfiniteLooper speed="80" direction="right">
							</InfiniteLooper> */}
							</div>
							<Routes>
								<Route path="/home" element={<Home currentUser={data} />}></Route>
								<Route path="/loginForm" element={<LoginForm />}></Route>

								<Route path="/contact" element={<Contact />}></Route>
								<Route path="/reset-password" element={<ResetPassword />}></Route>
								<Route path="/reset-password/:resetToken" element={<ResetPasswordPage />} />

								<Route
									path="/profile"
									element={
										<ProtectedRoute success={data?.success}>
											<Profile currentUser={data} />
										</ProtectedRoute>
									}
								/>
								<Route
									path="/order-history"
									element={
										<ProtectedRoute success={data?.success}>
											<OrderSubmitPage currentUser={data} />
										</ProtectedRoute>
									}
								/>
								<Route
									path="/messages"
									element={
										<ProtectedRoute success={data?.success}>
											<MessagesPage currentUser={data} />
										</ProtectedRoute>
									}
								/>
								<Route
									path="/checkout"
									element={
										<ProtectedRoute success={data?.success}>
											<CheckOut currentUser={data} />
										</ProtectedRoute>
									}
								/>
								<Route
									path="/dashboard"
									element={
										<ProtectedRoute success={data?.success}>
											<Dashboard currentUser={data} />
										</ProtectedRoute>
									}
								/>

								<Route
									path="/report"
									element={
										<ProtectedRoute success={data?.success}>
											<Report currentUser={data} />
										</ProtectedRoute>
									}
								/>
								<Route path="/shop" element={<Shop />}>
									<Route
										index
										element={<ProductList currentUser={data} />}
									></Route>
									<Route path="products" element={<ProductList />}>
										<Route index element={<ProductList />}></Route>
									</Route>
									<Route path=":id" element={<ProductDetail />}></Route>
									<Route
										path="create_product"
										element={
											<ProtectedRoute success={data?.success}>
												<CreateProduct />
											</ProtectedRoute>
										}
									></Route>
									<Route
										path="create_promo"
										element={
											<ProtectedRoute success={data?.success}>
												<CreatePromoCode />
											</ProtectedRoute>
										}
									></Route>
								</Route>
								<Route path="*" element={<Home />}></Route>
							</Routes>
							<Footer />
						</ProductContextProvider>
					</DashContextProvider>
				</AuthContextProvider>
			</LanguageContextProvider>
		</div>
	);
}

export default App;
