import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

export default function FilterDropdown(props) {
	const { options, products, category } = props;
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};


	return (
		<React.Fragment>
			<ButtonGroup
				sx={{ backgroundColor: "#094100", border: "none", color: "white", borderRadius: '0' }}
				ref={anchorRef}
				aria-label="split button"
			>
				<Button style={{ border: "none", color: "white" }}>Filter</Button>
				<Button
					sx={{
						borderLeft: "1px solid white",
						color: "white",
						borderTop: "none",
						borderRight: "none",
						borderBottom: "none",
					}}
					size="small"
					aria-controls={open ? "split-button-menu" : undefined}
					aria-expanded={open ? "true" : undefined}
					aria-label="select merge strategy"
					aria-haspopup="menu"
					onClick={handleToggle}
				>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Popper
				sx={{
					zIndex: 1,
				}}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === "bottom" ? "center top" : "center bottom",
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									<MenuItem
										onClick={(event) => {
											options("");
											handleClose(event);
										}}
									>
										{"All"}
									</MenuItem>
									{category
										.map((item, index) => (
											<MenuItem
												key={index}
												onClick={(event) => {
													options(item);
													handleClose(event);
												}}
											>
												{item.toUpperCase()}
											</MenuItem>
										))
										.sort()}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</React.Fragment>
	);
}
