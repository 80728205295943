import { React, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import FeaturedProductSingle from "./components/FeaturedProductSingle";
import { ProductContext } from "./ProductContext";
import { Button } from "@mui/material";
import FilterDropdown from "./FilterDropdown";

export default function ProductList(props) {
	const { currentUser } = props;
	const [keyword, setKeyword] = useState("");
	const [isClick, setIsClick] = useState(false);
	const { products, getProducts, getCategory, category } =
		useContext(ProductContext);
	const navigate = useNavigate();

	const loadProduct = async () => {
		await getProducts();
	};

	const checkClick = () => {
		setIsClick(true);
		navigate("/shop/create_product");
	};

	const filterByCategory = (txt) => {
		setKeyword(txt);
	};

	const filterArray = products.filter((product) =>
		product.category?.includes(keyword),
	);

	// const loadCategory = async () => {
	// 	await getCategory();
	// };

	useEffect(() => {
		setIsClick(false);
		loadProduct();
		// loadCategory();
	}, []);

	return (
		<div style={{ width: "100%" }}>
			{isClick === false ? (
				<div style={{ maxWidth: "100%" }}>
					<div
						className="container"
						style={{ textAlign: "right", padding: "15px" }}
					>
						{currentUser.data?.role === "admin" ? (
							<div>
								<Button
									variant="contained"
									onClick={checkClick}
									style={{ margin: "0  5px", backgroundColor: "#094100", borderRadius: '0' }}
								>
									New Product
								</Button>
								<Button
									style={{ margin: "0  5px", backgroundColor: "#094100", borderRadius: '0' }}
									variant="contained"
									onClick={() => navigate("/shop/create_promo")}
								>
									Create Promo Code
								</Button>
							</div>
						) : (
							<p>{""}</p>
						)}
						<div className="shopFilterButton">
							<FilterDropdown
								options={filterByCategory}
								products={products}
								// loadCategory={loadCategory}
								category={category}
							/>
						</div>
					</div>
					<div className="container" style={{ display: "inline-flex" }}>
						<div
							className="listLeft"
							style={{ color: "gray", textAlign: "left" }}
						>
							<Button
								sx={{ width: "100%", color: "white" }}
								onClick={() => filterByCategory("")}
							>
								All
							</Button>
							{products !== null ? (
								category
									.sort((a, b) => a.localeCompare(b))
									.map((el, index) => {
										return (
											<div key={index}>
												<Button
													sx={{
														width: "100%",
														color: "white",
													}}
													key={index}
													onClick={() => filterByCategory(el)}
												>
													{el.toUpperCase()}
												</Button>
											</div>
										);
									})
							) : (
								<p></p>
							)}
						</div>
						<div className="listRight">
							{filterArray
								.sort((a, b) => a.title.localeCompare(b.title))
								.map((el, index) => {
									return (
										<FeaturedProductSingle
											key={index}
											id={el.id}
											title={el.title}
											description={el.description}
											price={el.price}
											img={el.url}
											sale={el.sale}
											currentUser={currentUser}
											points_needed={el.points_needed}
										/>
									);
								})}
						</div>
					</div>
				</div>
			) : (
				<Outlet />
			)}
		</div>
	);
}
