import React, { useState, useEffect } from "react";
import axios from "axios";
import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TablePagination,
} from "@mui/material";
import MessageReply from "./MessageReply";
import Filter from "./Filter";
import { url } from "../url";

const MessagesPage = (props) => {
	const { currentUser } = props;
	const [messages, setMessages] = useState([]);
	const [page, setPage] = useState(0);
	const [openReply, setOpenReply] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [activeTab, setActiveTab] = useState("received");
	const [selectedMessage, setSelectedMessage] = useState(null);
	const handleChangePage = (_, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	useEffect(() => {
		axios
			.get(url + "/get_messages")
			.then((response) => {
				setMessages(response.data);
			})
			.catch((error) => {
				console.error("Error fetching messages:", error);
			});
	}, []);

	const handleTabClick = (tab) => {
		setActiveTab(tab);
		setPage(0);
	};

	const formatDate = (dateString) => {
		const dateObject = new Date(dateString);
		const options = {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			hour12: false,
		};
		return dateObject.toLocaleString("en-US", options);
	};

	const filteredMessages = messages[activeTab] || [];

	return (
		<div style={{ maxWidth: "100%" }}>
			<div style={{ textAlign: "right", padding: "12px" }}>
				<Filter
					sent={() => handleTabClick("received")}
					received={() => handleTabClick("sent")}
				/>
			</div>
			<div className="container" style={{ display: "inline-flex" }}>
				<div
					className="listLeft"
					style={{
						color: "gray",
						textAlign: "left",
						minHeight: "100vh",
						paddingTop: "25px",
					}}
				>
					<Button
						sx={{ width: "100%", textAlign: "left", color: "white" }}
						onClick={() => handleTabClick("received")}
					>
						Inbox
					</Button>
					<Button
						sx={{ width: "100%", textAlign: "left", color: "white" }}
						onClick={() => handleTabClick("sent")}
					>
						Sent
					</Button>
				</div>
				<div className="listRight">
					<TableContainer
						component={Paper}
						sx={{ background: "rgba(23, 20, 20, 0.85)", color: "#fff" }}
					>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ color: "#fff" }}>DATE</TableCell>
									<TableCell sx={{ color: "#fff" }} align="left">
										{activeTab === "received" ? "FROM" : "TO"}
									</TableCell>
									<TableCell sx={{ color: "#fff" }} align="left">
										SUBJECT
									</TableCell>
									<TableCell sx={{ color: "#fff" }} align="left">
										Status
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredMessages
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => (
										<TableRow
											onClick={() => setSelectedMessage(row)}
											key={row.id}
											sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
										>
											<TableCell
												sx={{ color: "#fff" }}
												component="th"
												scope="row"
											>
												{formatDate(row.created)}
											</TableCell>
											<TableCell sx={{ color: "#fff" }} align="left">
												{activeTab === "received" ? (
													<>{row.send_from}</>
												) : (
													<>{row.send_to}</>
												)}
											</TableCell>
											<TableCell sx={{ color: "#fff" }} align="left">
												<Button sx={{ color: "white", paddingLeft: "0" }}>
													{"Question about #" +
														(row.orderNumber?.toUpperCase() || "")}
												</Button>
											</TableCell>
											<TableCell sx={{ color: "#fff" }} align="left">
												<Button sx={{ color: "white", paddingLeft: "0" }}>
													{row.isRead === 0 ? <>New</> : <></>}
												</Button>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
						<TablePagination
							sx={{ color: "white", textAlign: "center" }}
							rowsPerPageOptions={[5, 10, 100]}
							component="div"
							count={filteredMessages.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</TableContainer>
					<MessageReply
						open={!!selectedMessage}
						data={selectedMessage}
						setSelectedMessage={setSelectedMessage}
						currentUser={currentUser}
						activeTab={activeTab}
					/>
				</div>
			</div>
		</div>
	);
};

export default MessagesPage;
