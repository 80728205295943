import { useQuery } from "@tanstack/react-query";
import React, { useContext } from "react";
import { ProductContext } from "../ProductContext";
import axios from "axios";
import { url } from "../url";

// export const useProducts = () => {
// 	const { getProducts } = useContext(ProductContext);
// 	const { data, isLoading, isError, refetch } = useQuery(
// 		["all_products"],
// 		async () => {
// 			const response = await getProducts();
// 			console.log(response);
// 			return response.data;
// 		},
// 	);
// 	return { data, isLoading, isError, refetch };
// };

export const useProductById = (id) => {
	const { isLoading, data } = useQuery(
		["productById", id],
		async () => {
			const response = await axios.get(url + "/product/" + id);
			return response.data;
		},
	);
	return { isLoading, data };
};
