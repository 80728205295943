import { Chip, Stack } from "@mui/material";
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { ProductContext } from "../ProductContext";

const CartItem = ({ item, data, disabled, currentPath }) => {
	const navigate = useNavigate();
	const {
		itemCount,
		setItemCount,
		storageData,
		addToCart,
		removeFromCart,
		getTotal,
		total,
		subtotal,
		salesTax,
		delivery,
		tax,

	} = useContext(ProductContext);

	useEffect(() => { }, [removeFromCart, addToCart, item]);
	if (item.amount === 0)
		return (
			<p style={{ textAlign: "center", alignItems: "center !important" }}>
				Cart is currently empty, return to{" "}
				<Button
					// variant="contained"
					onClick={() => {
						navigate("/shop");
					}}
				>
					SHOP
				</Button>
			</p>
		);

	return (
		<div className="cartItemContent">
			{item?.qt !== 0 ? (
				<div>
					<p
						style={{
							fontWeight: "bold",
							color: "white",
							marginBottom: "0",
							paddingTop: "15px",
						}}
					>
						{item.item?.title.toUpperCase()}
					</p>
					<div className="d-flex justify-content-between">
						<img
							src={item.item?.url}
							alt={item.item?.title}
							style={{ height: "50px", width: "50px", alignSelf: "center" }}
						/>
						<span
							style={{ height: "50px", alignSelf: "center", color: "white" }}
						>
							{item?.option.toUpperCase()}
						</span>
						<div className=" mt-3">
							<Stack
								direction="row"
								spacing={1}
								sx={{ alignItems: "center", paddingRight: "25px" }}
							>
								<button
									style={{ border: 0, background: disabled === true || currentPath === '/checkout' ? "gray" : "black" }}
									onClick={() => removeFromCart(item)}
									disabled={disabled === true || currentPath === '/checkout'}

								>
									<Chip
										label="-"
										disabled={disabled === true || currentPath === '/checkout'}
										sx={{
											color: "white",
											// backgroundColor: "black",
											width: "40px",
											height: "25px;",
										}}
									/>
								</button>
								<p
									style={{
										color: "white",
										width: "25px",
										textAlign: "center",
									}}
								>
									{item.qt}
								</p>
								<button style={{ border: 0, background: disabled === true || currentPath === '/checkout' ? "gray" : "black" }} disabled={disabled === true || currentPath === '/checkout'} onClick={() => addToCart(item)}

								>
									<Chip
										label="+"
										disabled={disabled === true || currentPath === '/checkout'}
										sx={{
											color: "white",
											// backgroundColor: "black",
											width: "40px",
											height: "20px",
										}}
									/>
								</button>
							</Stack>
							<div style={{ width: "100%", marginTop: "10px" }}>
								<p style={{ color: "white", textAlign: "center" }}>
									Price: ${item.item?.price}
								</p>
							</div>
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default CartItem;
