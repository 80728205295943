import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function SimpleAccordion(props) {
	const { data } = props;

	return (
		<div style={{ margin: "15px 0" }}>
			<Accordion
				elevation={4}
				sx={{
					backgroundColor: "rgba(23, 20, 20, 0.6)",
					color: "white",
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography sx={{ fontWeight: "500" }}>
						Comments : {data.length}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<div>
						{data ? (
							data.map((el, index) => {
								const dateObj = new Date(el.created);
								const formattedDateTime = dateObj.toLocaleString("en-US", {
									year: "numeric",
									month: "2-digit",
									day: "2-digit",
									hour: "2-digit",
									minute: "2-digit",
								});

								return (
									<div key={index}>
										<Typography
											key={index}
											style={{
												width: "100%",
												backgroundColor:
													index % 2 === 0 ? "rgb(9, 65, 0)" : "black",
												// color: index % 2 === 0 ? "white" : "black",
												color: "white",

												padding: "15px",
												textAlign: "left",
											}}
										>
											<div style={{ width: "100%", marginRight: "25px" }}>
												<span>{formattedDateTime}</span>
												<span style={{ float: "right" }}>
													{el.send_from === 3 || el.send_from === 75
														? "From Admin"
														: ""}
												</span>
											</div>
											<div>{el.comment}</div>
										</Typography>
									</div>
								);
							})
						) : (
							<></>
						)}
					</div>
				</AccordionDetails>
			</Accordion>
			{
				// 	<Accordion
				// 	elevation={4}
				// 	sx={{
				// 		backgroundColor: "rgba(23, 20, 20, 0.6)",
				// 		color: "white",
				// 	}}
				// >
				// 	<AccordionSummary
				// 		expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
				// 		aria-controls="panel1a-content"
				// 		id="panel1a-header"
				// 	>
				// 		<Typography sx={{ fontWeight: "500" }}>
				// 			Payment History : {data.length}
				// 		</Typography>
				// 	</AccordionSummary>
				// 	<AccordionDetails>
				// 		<div>
				// 			{data.map((el, index) => {
				// 				const dateObj = new Date(el.updatedAt);
				// 				const formattedDateTime = dateObj.toLocaleString("en-US", {
				// 					year: "numeric",
				// 					month: "2-digit",
				// 					day: "2-digit",
				// 					hour: "2-digit",
				// 					minute: "2-digit",
				// 				});
				// 				return (
				// 					<Typography
				// 						key={index}
				// 						style={{
				// 							width: "100%",
				// 							backgroundColor: index % 2 === 0 ? "#1976d2" : "white",
				// 							color: index % 2 === 0 ? "white" : "black",
				// 							padding: "15px",
				// 							textAlign: "left",
				// 						}}
				// 					>
				// 						<span style={{ width: "20%", marginRight: "25px" }}>
				// 							{formattedDateTime}
				// 						</span>
				// 						<span style={{ width: "80%" }}>{el.payment_link}</span>
				// 					</Typography>
				// 				);
				// 			})}
				// 		</div>
				// 	</AccordionDetails>
				// </Accordion>
			}
		</div>
	);
}
