import { React, useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import h3social_main from "../images/h3social_main_removebg.jpg";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import FeaturedProductsList from "./FeaturedProductsList";
import leaves from "../images/Leaves.png";
import { LanguageContext } from "../controller/LanguageContext";

export default function Home() {
	const isSmallScreen = window.innerWidth <= 768;
	const { isEn } = useContext(LanguageContext);

	const inlineStyle = {
		backgroundImage: isSmallScreen
			? `linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)), url(${h3social_main})`
			: `url(${h3social_main})`,
		backgroundRepeat: "no-repeat",
		backgroundPositionX: "center",
		backgroundSize: "800px",
		margin: "15px",
		height: "600px",
	};
	return (
		<>
			<Container style={{ padding: "35px 0" }}>
				{!isEn ? (
					<div className="homeIntroContainer fadeIn" style={inlineStyle}>
						<span
							style={{
								textAlign: "left",
								padding: "45px",
								width: "100%",
								margin: "auto",
							}}
						>
							<h1
								style={{ color: "white", marginBottom: "35px" }}
								className="slideRight"
							>
								Elevate Your Experience with
								<div style={{ color: "#094100", fontWeight: "bold" }}>
									H3BUDZ
								</div>
							</h1>

							<h5
								className="slideLeft"
								style={{
									color: "white",
									fontWeight: "lighter",
									lineHeight: "2rem",
								}}
							>
								Your destination for premium cannabis products.
							</h5>
							<h5
								className="slideRight"
								style={{
									color: "white",
									fontWeight: "lighter",
									lineHeight: "2rem",
								}}
							>
								Explore a curated selection of top-quality strains, edibles,
								concentrates, and accessories designed to enhance your cannabis
								journey.
							</h5>
							<h5
								className="slideLeft"
								style={{
									color: "white",
									fontWeight: "lighter",
									lineHeight: "2rem",
								}}
							>
								Discover purity, potency, and sustainability in every product.
								Let's elevate together.
							</h5>
						</span>
					</div>
				) : (
					<div className="homeIntroContainer fadeIn" style={inlineStyle}>
						<span
							style={{
								textAlign: "left",
								padding: "45px",
								width: "100%",
								margin: "auto",
							}}
						>
							<h1
								style={{ color: "white", marginBottom: "35px" }}
								className="slideRight"
							>
								Elevate Your Experience with
								<div style={{ color: "#094100", fontWeight: "bold" }}>
									H3BUDZ
								</div>
							</h1>

							<h5
								className="slideLeft"
								style={{
									color: "white",
									fontWeight: "lighter",
									lineHeight: "2rem",
								}}
							>
								您的優質大麻產品目的地。
							</h5>
							<h5
								className="slideRight"
								style={{
									color: "white",
									fontWeight: "lighter",
									lineHeight: "2rem",
								}}
							>
								探索經過精心挑選的高品質品種、食用品、濃縮品和配件，旨在豐富您的大麻之旅。
							</h5>
							<h5
								className="slideLeft"
								style={{
									color: "white",
									fontWeight: "lighter",
									lineHeight: "2rem",
								}}
							>
								發現每一款產品中的純淨、效力和可持續性。讓我們一起提升。
							</h5>
						</span>
					</div>
				)}

				<div style={{ paddingBottom: "80px" }}>
					<Link to="/shop">
						<Button
							style={{
								background: "#094100",
								borderRadius: "0",
								border: "none",
								padding: "10px 25px 10px 25px",
								width: "150px",
							}}
						>
							{!isEn ? <strong>SHOP ↓</strong> : <strong>商店</strong>}
						</Button>
					</Link>
				</div>
				<div
					style={{
						backgroundImage: `url(${leaves})`,
						height: "150px",
						boxShadow: "inset 0 0 10px #000000",
					}}
				></div>
			</Container>

			<FeaturedProductsList />

		</>
	);
}
