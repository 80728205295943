import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { url } from "../url";
import ConfirmationPopup from './ConfirmationPopup';


export default function ResetPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [openConFirmationPopup, setOpenConfirmationPopup] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(url + '/reset-password', { email });
            setMessage(response.data.message);
            setOpenConfirmationPopup(true);
            setTimeout(() => {
                navigate("/shop");
            }, 2100);
        } catch (error) {
            setMessage('Error requesting password reset. Please try again.');
        }
    };
    return (

        <div className="Auth-form-container mt-5">
            <h2 style={{ color: 'orange' }}>Reset Password</h2>
            <form className='Auth-form' onSubmit={handleSubmit}>
                <div className="Auth-form-content">
                    <p className="errorMessage">{message}</p>
                    <div
                        className="form-group mt-3 whiteText col-8"
                        style={{ margin: "auto" }}
                    >
                        <label>Email address</label>
                        <input
                            type="email"
                            className="form-control mt-1"
                            placeholder="Enter email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="d-grid gap-2 mt-5">
                        <button
                            style={{
                                backgroundColor: "#094100",
                                border: "none",
                                width: "200px",
                                margin: "auto",
                            }}
                            type="submit"
                            className="btn btn-primary"
                        >
                            Request Password Reset
                        </button>
                    </div>
                </div>
            </form>
            <ConfirmationPopup
                openConFirmationPopup={openConFirmationPopup}
                setOpenConfirmationPopup={setOpenConfirmationPopup}
            />
        </div>
    )
}
