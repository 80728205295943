import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { url } from "../url";
import { Button } from "@mui/material";
import AlertPopUp from "./AlertPopUp";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import h3Logo from "../images/h3logo.png";
import { ProductContext } from "../ProductContext";
import FormSelect from "./FormSelect";
import AnimationSharpIcon from "@mui/icons-material/AnimationSharp";
import CircularProgress from '@mui/material/CircularProgress';
import ToggleSwitch from "./ToggleSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



export default function CheckOut(props) {
	const { currentUser } = props;
	const {
		cartItem,
		addToCart,
		removeFromCart,
		subtotal,
		delivery,
		salesTax,
		total,
		setItemCount,
		setDelivery,
		shippingMethod,
		selectedMethod,
		setSelectedMethod,
		temperPoints,
		setTemperPoints,
		setSubtotal,
		tipsMethod,
		tips,
		paymentMethod,
		setSelectedPaymentMethod,
		selectedPaymentMethod,
		paymentType,
		getTotal

	} = useContext(ProductContext);
	const [orderItems, setOrderItems] = useState([]);
	const [updatedTotal, setUpdatedTotal] = useState(subtotal);
	const [open, setOpen] = useState(false);
	const [color, setColor] = useState("");
	const [message, setMessage] = useState("");
	const [code, setCode] = useState("");


	const [firstName, setFirstName] = useState(
		currentUser.data.firstName === "null" ? "" : currentUser.data.firstName,
	);
	const [lastName, setLastName] = useState(
		currentUser.data.lastName === "null" ? "" : currentUser.data.lastName,
	);
	const [email, setEmail] = useState(
		currentUser.data.email === "null" ? "" : currentUser.data.email,
	);
	const [address, setAddress] = useState(
		currentUser.data.address === "null" ? "" : currentUser.data.address,
	);
	const [city, setCity] = useState(
		currentUser.data.city === "null" ? "" : currentUser.data.city,
	);
	const [state, setState] = useState(
		currentUser.data.state === "null" ? "" : currentUser.data.state,
	);
	const [zipcode, setZipcode] = useState(
		currentUser.data.zipcode === "null" ? "" : currentUser.data.zipcode,
	);
	const [phone, setPhone] = useState(
		currentUser.data.phone === "null" ? "" : currentUser.data.phone,
	);
	const [redeemed, setRedeemed] = useState([]);
	const [instruction, setInstruction] = useState("");
	const [inPrograss, setInPrograss] = useState(false)
	const [discount, setDiscount] = useState(0)
	const [discountMultiplyer, setDiscountMultiplyer] = useState(0)
	const [freeBefore12, setFreeBefore12] = useState(false)

	let [giftItem, setGiftItem] = useState({});
	let [redeemedItem, setRedeemedItem] = useState([])


	const navigate = useNavigate();

	const handleClick = (txt, color) => {
		setOpen(true);
		setMessage(txt);
		setColor(color);
	};

	const handleToggleChange = (newState) => {
		setFreeBefore12(newState)
	};

	// const retriveIncompleteOrder = async () => {
	// 	await axios
	// 		.get(url + "/retrive_incomplete_order", currentUser.userId)
	// 		.then((response) => {
	// 			setOrerItems(response.data);
	// 		});
	// };

	const clearLocalStorage = () => {
		localStorage.setItem("h3", JSON.stringify([]));
	};

	const handleSubmit = (order) => {
		submitOrder(order)
		setInPrograss(true)
	}
	const submitOrder = async (order) => {
		if (
			address.length > 0 &&
			city.length > 0 &&
			state.length > 0 &&
			zipcode.length > 0 &&
			phone.length > 0
		) {
			try {
				await axios
					.post(url + "/submit_order", {
						order,
						total: subtotalWithDiscountProduct === 0 ?
							(redeemedItem.length ? parseFloat((total - updatedSubtotal + (total - updatedSubtotal < 50 ? 10 : delivery)).toFixed(2)) : total - discount)
							: subtotalWithDiscountProduct + parseFloat(parseFloat(subtotalWithDiscountProduct * 0.0888).toFixed(2)) + tips + (subtotalWithDiscountProduct < 50 ? 10 : 0),
						// discount: total * (1 - parseFloat(giftItem.discount_value)).toFixed(2),
						subtotal: giftItem.discount_value > 0 ? (subtotalWithDiscountProduct - updatedSubtotal) : (subtotal - updatedSubtotal),
						salesTax: giftItem.discount_value > 0 ? parseFloat(subtotalWithDiscountProduct * 0.0888).toFixed(2) : salesTax,
						// delivery: subtotal - updatedSubtotal < 50 ? 10 : 0,
						delivery: (giftItem.discount_value > 0 ? (subtotalWithDiscountProduct - updatedSubtotal) : (subtotal - updatedSubtotal)) < 50 ? 10 : 0,
						address: address,
						city: city,
						state: state,
						zipcode: zipcode,
						phone: phone,
						instruction,
						giftItem: giftItem ? giftItem : null,
						code: code,
						redeemedItem: redeemedItem ? redeemedItem : null,
						reminingPoints: temperPoints,
						currentPoints: currentUser.data.points,
						shipping_method: "standard", //selectedMethod,
						tipAmount: parseFloat(tips.toFixed(2)),
						discount_amount: discount,
						paymentMethod: selectedPaymentMethod.label,
						freeBefore12: freeBefore12
					})
					.then((response) => {
						try {
							if (
								response.data.message ===
								"Order completed , plesase check email for instruction."
							) {
								handleClick("Order created.");
								setTimeout(() => {
									navigate("/order-history");
									clearLocalStorage();
									setItemCount(0);
								}, [2000]);
							} else {
								handleClick("Something went wrong!", "error");
							}
						} catch (error) {
							handleClick(
								"Ops...Failed to create order, please contact admin!",
							);
						}
					});
			} catch (error) {
				console.log(error);
			}
		} else {
			handleClick("All fields are required", "error");
		}
	};

	// const confirmOrder = async () => {
	// 	const formData = new FormData();

	// 	if (
	// 		address.length !== 0 &&
	// 		city.length !== 0 &&
	// 		state.length !== 0 &&
	// 		zipcode.length !== 0 &&
	// 		phone.length !== 0
	// 	) {
	// 		try {
	// 			formData.append("orderNumber", orderItems[0].orderNumber);
	// 			formData.append("shipping_address", address);
	// 			formData.append("shipping_city", city);
	// 			formData.append("shipping_state", state);
	// 			formData.append("shipping_zipcode", zipcode);
	// 			formData.append("shipping_phone", phone);
	// 			formData.append("instruction", instruction);
	// 			axios
	// 				.put(url + "/confirm_order", formData, {
	// 					params: {
	// 						grandTotal: grandTotal - redeemAmount,
	// 						order: orderItems,
	// 						shipping: shipping,
	// 						redeemAmount: redeemAmount,
	// 						giftItem: giftItem,
	// 					},
	// 				})
	// 				.then((response) => {
	// 					handleClick(response.data.message);
	// 					setTimeout(() => {
	// 						window.location.replace("/shop");
	// 					}, 3000);
	// 				});
	// 		} catch (error) {
	// 			handleClick("Something went wrong, please contact admin!", "error");
	// 		}
	// 	} else {
	// 		handleClick("all fields are required", "error");
	// 	}
	// };

	const shipping = total < 50 ? 10 : 0;

	const grandTotal = total + shipping;
	const [redeemAmount, setRedeemAmount] = useState(0);
	const setRedeem = () => {
		const points = parseInt(currentUser.data.points);
		const result = points === 0 ? 0 : points / 100;
		setRedeemAmount(result);
	};
	const [verifyLoading, setVerifyLoading] = useState(false);
	const [test, setTest] = useState([])
	const [subtotalWithDiscountProduct, setSubTotalWithDiscountProduct] = useState(0)
	const [preDiscountTotal, setPreDiscountTotal] = useState(0)

	const validateCode = async (txt) => {
		setVerifyLoading(true);
		try {
			const response = await axios.get(url + "/validate_promocode", { params: { code: txt } });
			const data = response.data;
			setGiftItem(data);
			let updateSub = 0; // Initialize subtotal
			let updateDiscount = 0

			// If data.product_id is not 0, calculate the discount
			let isMatchFound = false;
			if (data.product_id !== 0) {
				const updatedList = orderItems.map((el) => {
					if (el.id === data.product_id) {
						isMatchFound = true

						// Calculate discounted price
						const updatedPrice = el.item.price * parseFloat(data.discount_value);

						updateSub += updatedPrice * el.qt; // Add the discounted price to subtotal
						updateDiscount = (el.item.price - updatedPrice) * el.qt

						// Return updated item with discounted price
						return {
							...el,
							item: {
								...el.item,
								price: updatedPrice, // Update the price inside the item object
							},
						};
					} else if (el.id !== data.product_id && data.discount_value === '0.00') {
						console.log(data)
						isMatchFound = true
						// Add the original item price to subtotal
						updateSub += el.item.price * el.qt;

						// Return original item without changes
						return el;
					} else {
						// Add the original item price to subtotal
						updateSub += el.item.price * el.qt;

						// Return original item without changes
						return el;
					}
				});

				// Update state with new subtotal and item list
				setDiscount(updateDiscount)
				setSubTotalWithDiscountProduct(updateSub);
				setTest(updatedList);
				if (!isMatchFound) {
					handleClick('No related item found..., Redirect in 3 second', "error");
					setTimeout(() => {
						window.location.reload();
					}, 2000); // Reload the page after 3 seconds
				}
			} else {
				// If data.product_id is 0, calculate the total without discounts
				let total = 0
				orderItems.map((el) => {
					total += el.item.price * el.qt
					updateDiscount = (updateSub += el.item.price * el.qt)
				});
				updateSub = total * parseFloat(data.discount_value)
				// Update state with new subtotal
				setSubTotalWithDiscountProduct(updateSub);
				// Update discount value
				setDiscount(total - updateSub)
			}

			if (response.data.message) {
				handleClick(response.data.message, "error");
			}
		} catch (error) {
			console.error("Error validating promo code:", error);
		} finally {
			setVerifyLoading(false);
		}
	};

	const redeemItem = (item) => {
		setRedeemed((prevDisabledButtons) => [...prevDisabledButtons, item.id]);
		setTemperPoints((prevPoints) => {
			const updatedPoints = prevPoints - item.item.points_needed * item.qt;
			return updatedPoints;
		});
		setRedeemedItem((prevItem) => [...prevItem, item])
	};

	const plus = (item) => {
		addToCart(item)
		// setRedeemed((prevRedeemed) => prevRedeemed.filter((id) => id !== item.id));
		window.location.reload()

	}

	const minus = (item) => {
		removeFromCart(item)
		// setRedeemed((prevRedeemed) => prevRedeemed.filter((id) => id !== item.id));
		window.location.reload()
	}

	const removeItemById = (id) => {
		setRedeemedItem((prevItems) => prevItems.filter(item => item.id !== id));

	};

	const removeCode = () => {
		setGiftItem([])
		setTest([])
		setSubTotalWithDiscountProduct(0)
		window.location.reload()
	}

	const handleRemoveButtonClick = (itemId) => {
		removeItemById(itemId);
		setRedeemed((prevRedeemed) => prevRedeemed.filter((id) => id !== itemId));
	};

	const [updatedSubtotal, setUpdatedSubtotal] = useState(() => {
		const total = redeemedItem?.reduce((acc, el) => acc + el.item.price * el.qt, 0);
		return total || 0; // Handle the case where redeemedItem is undefined or empty
	});


	useEffect(() => {
		setOrderItems(cartItem)
		setTemperPoints(currentUser.data.points)
		// retriveIncompleteOrder();
		const total = redeemedItem?.reduce((acc, el) => acc + el.item.price * el.qt, 0);
		const preDiscont = cartItem.reduce((acc, el) => acc + el.item.price * el.qt, 0)
		setPreDiscountTotal(preDiscont)
		setDiscountMultiplyer(parseFloat(giftItem?.discount_value));
		// setDiscount(giftItem?.discount_value > 0 ? (subtotal - updatedSubtotal) - (subtotal - updatedSubtotal) * parseFloat(giftItem?.discount_value) : 0)
		setUpdatedSubtotal(total || 0);
		setTemperPoints((prevPoints) => {
			const redeemedPoints = redeemedItem?.reduce((acc, el) => acc + el.item.points_needed * el.qt, 0) || 0;
			return prevPoints - redeemedPoints;
		});
	}, [giftItem, cartItem, redeemedItem, currentUser.data.points, subtotal, temperPoints, orderItems]);

	if (orderItems.length === 0) {
		return (
			<div style={{ color: "white", fontWeight: "500" }}>
				<p>No Items Found..</p>
				<Button
					variant="conatined"
					sx={{ backgroundColor: "#094100" }}
					onClick={() => navigate("/shop")}
				>
					Back To Shop
				</Button>
			</div>
		);
	}


	return (
		<div className="container">
			<AlertPopUp
				open={open}
				setOpen={setOpen}
				message={message}
				handleClick={handleClick}
				color={color}
			/>
			{orderItems.length > 0 ? (
				<div>
					<h5
						style={{
							backgroundColor: "#094100",
							color: "white",
							padding: "10px",
						}}
					>
						REVIEW ORDER #{orderItems[0]?.orderNumber?.toUpperCase()}
					</h5>
					<table className="orderReview">
						<thead>
							<tr className="whiteText">
								<th className="orderHistoryImage"></th>
								<th>Product</th>
								<th>Option</th>
								<th>QT</th>
								<th>Price</th>
								<th>Redeem</th>
							</tr>
						</thead>
						<tbody style={{ fontWeight: "500" }}>
							{(giftItem.product_id > 0 ? test : orderItems).map((el, index) => {
								const isEven = index % 2 === 0;
								const rowClass = isEven ? "even-row" : "odd-row";
								return (
									<tr key={index} className={rowClass}>
										<td className="orderHistoryImage">
											<img
												src={el.item.url}
												alt={el.item.url}
												style={{ width: "70px", height: "auto" }}
											/>
										</td>
										<td>{el.item?.title.toUpperCase()}</td>
										<td>{el?.option.toUpperCase()}</td>
										<td>
											<div className="d-flex justify-content-around align-items-center">
												<button
													onClick={() => minus(el)}
													style={{
														background: "none",
														fontWeight: "900",
														width: "15px",
														padding: "0",
														border: "none",
													}}
												>
													<p style={{ margin: "auto" }}>-</p>
												</button>
												<p style={{ margin: "auto" }}>x{el.qt}</p>
												<button
													onClick={() => plus(el)}
													style={{
														background: "none",
														fontWeight: "900",
														width: "15px",
														padding: "0",
														border: "none",
													}}
												>
													<p style={{ margin: "auto" }}>+</p>
												</button>
											</div>
										</td>
										<td>$ {el.item.price}</td>

										{el.item.points_needed === 0 ? (
											<td></td>
										) : (
											<td>
												{redeemed.includes(el.id) ? <Button onClick={() => handleRemoveButtonClick(el.item.id)}>Undo</Button> :
													<Button
														variant="contained"
														style={{
															padding: "2px",
															backgroundColor: temperPoints - el.item.points_needed * el.qt < 0 || giftItem.id ? "gray" : "#094100",
															color: temperPoints - el.item.points_needed * el.qt < 0 || giftItem.id ? "darkgray" : "white",
															fontSize: "15px",
														}}
														onClick={() => redeemItem(el)}
														disabled={temperPoints - el.item.points_needed * el.qt < 0 || !!giftItem.id}
													>
														<AnimationSharpIcon
															sx={{ color: "gold", fontSize: "15px" }}
														/>
														{el.item.points_needed * el.qt}

													</Button>

												}
											</td>
										)}
									</tr>
								);
							})}
							{giftItem.id ? (
								<tr
									key={giftItem.id ? giftItem.id : 1}
									style={{
										backgroundColor: "green",
										color: "white",
									}}
								>
									<td className="orderHistoryImage">
										<img
											src={giftItem?.url ? giftItem.url : h3Logo}
											alt={giftItem?.url}
											style={{ width: "70px", height: "auto" }}
										/>
									</td>
									<td>
										{giftItem.description !== null
											? giftItem.description
											: giftItem.note}
									</td>
									<td></td>
									<td></td>
									<td>{giftItem.id ? "FREE" : ""}</td>
									<td></td>
								</tr>
							) : (
								<tr></tr>
							)}
							{redeemedItem?.map(el => {
								return <tr
									key={el.id ? el.id : 1}
									style={{
										backgroundColor: "green",
										color: "white",
									}}
								>
									<td className="orderHistoryImage">
										<img
											src={el?.item.url ? el.item.url : h3Logo}
											alt={el?.item.url}
											style={{ width: "70px", height: "auto" }}
										/>
									</td>
									<td>
										{el.item.title !== null
											? el.item.title.toUpperCase()
											: el.note}
									</td>
									<td>{el?.option}</td>
									<td>x{el?.qt}</td>
									<td>{el.id ? "-$" + el?.item.price : ""}</td>
									<td>-<AnimationSharpIcon sx={{ color: 'gold' }} />{el.item.points_needed * el.qt}</td>
								</tr>
							})}

						</tbody>
					</table>
					<div
						style={{
							padding: "25px",
						}}
						className="checkoutAmountContainer"
					>
						{
							redeemedItem[0] ? <></> :
								<>
									<p className="d-flex whiteText">
										<span style={{ width: "60%", textAlign: "right" }}>
											Promo Code:
										</span>
										{giftItem.promoCode ? (
											<span
												style={{
													width: "40%",
													textAlign: "right",
													fontWeight: "bold",
												}}
											>
												{giftItem.promoCode.toUpperCase()}
											</span>
										) : (
											<span style={{ width: "40%", textAlign: "right" }}>
												<input
													style={{ width: "110px", padding: 0 }}
													onChange={(e) => setCode(e.target.value)}
												/>
											</span>
										)}
									</p>

									<span className="d-flex mb-3">
										<span style={{ width: "60%", textAlign: "right" }}></span>
										<span style={{ width: "40% " }}>
											{giftItem.promoCode ? (
												<Button
													variant="contained"
													color="error"
													style={{
														float: "right",
														color: "white",
														padding: "6px",
														fontSize: "10px",
													}}
													onClick={removeCode}
												>
													Remove
												</Button>
											) : (
												<Button
													variant="contained"
													style={{
														float: "right",
														padding: "6px",
														backgroundColor: "#094100",
														color: "white",
														fontSize: "10px",
													}}
													onClick={() => validateCode(code)}
												>
													{verifyLoading === true ? (
														<>
															<span
																className="spinner-border spinner-border-sm"
																role="status"
																aria-hidden="true"
															></span>
															<span className="visually-hidden">Loading...</span>
														</>
													) : (
														<>Verify</>
													)}
												</Button>
											)}
										</span>
									</span>
								</>
						}
						<p className="d-flex whiteText">
							<span style={{ width: "60%", textAlign: "right" }}>Payment Method:<br /></span>
							<span style={{ width: "40%", textAlign: "right" }}>
								<FormSelect
									data={paymentMethod}
									setSelectedMethod={setSelectedPaymentMethod}
								/>

							</span>
						</p>
						<p className="d-flex whiteText">
							<span style={{ width: "60%", textAlign: "right" }}>Next day delivery (Free edible)<br /></span>
							<span style={{ width: "40%", textAlign: "right" }}>
								<ToggleSwitch onToggle={handleToggleChange} />

							</span>
						</p>
						<p className="d-flex whiteText">
							<span style={{ width: "60%", textAlign: "right" }}>
								Available Points:
							</span>
							<span style={{ width: "40%", textAlign: "right" }}>
								<AnimationSharpIcon sx={{ color: "gold" }} />
								{temperPoints}
							</span>
						</p>
						<p className="d-flex whiteText">
							<span style={{ width: "60%", textAlign: "right" }}>Tips:<br />
								<p style={{ fontSize: '8px', width: '100px', float: 'right' }}> ( All tips will go to driver and we will throw in a mysterious gift )</p></span>
							<span style={{ width: "40%", textAlign: "right" }}>
								<FormSelect
									data={tipsMethod}
									setSelectedMethod={setSelectedMethod}
								/>
							</span>
						</p>
						{
							selectedMethod.id !== 1 ?
								<p className="d-flex whiteText">
									<span style={{ width: "60%", textAlign: "right" }}>
										Tip Amount:
									</span>
									<span style={{ width: "40%", textAlign: "right" }}>
										$ {parseFloat(tips.toFixed(2))}
									</span>
								</p> : <></>
						}
						<p className="d-flex whiteText">
							<span style={{ width: "60%", textAlign: "right" }}>
								Delivery:
							</span>
							<span style={{ width: "40%", textAlign: "right" }}>
								{subtotalWithDiscountProduct === 0 ? (redeemedItem.length ? (subtotal - updatedSubtotal < 50 ? '$' + 10 : 'FREE') : (subtotal - updatedSubtotal < 50 ? '$' + 10 : 'FREE')) : (redeemedItem.length ? (subtotalWithDiscountProduct - updatedSubtotal < 50 ? '$' + 10 : 'FREE') : (subtotalWithDiscountProduct - updatedSubtotal < 50 ? '$' + 10 : 'FREE'))}
							</span>
						</p>
						{
							giftItem.length > 0 || giftItem.discount_value > 0 ? (
								<p className='d-flex whiteText'>
									<span style={{ width: "60%", textAlign: "right" }}>
										Discount:
									</span>
									<span style={{ width: "40%", textAlign: "right" }}>
										- $ {discount}
									</span>
								</p>

							) : <></>
						}
						<p className="d-flex whiteText">
							<span style={{ width: "60%", textAlign: "right" }}>
								Subtotal:
							</span>
							<span style={{ width: "40%", textAlign: "right" }}>
								<p style={{
									display: preDiscountTotal !== giftItem.discount_value && giftItem.discount_value > 0
										? "block"
										: "none",
									marginBottom: '0',
									textDecoration:
										preDiscountTotal !== giftItem.discount_value && giftItem.discount_value > 0
											? "line-through"
											: "none"
								}}>
									$ {preDiscountTotal}
								</p>
								<br />

								$ {giftItem.discount_value > 0 ? (subtotalWithDiscountProduct - updatedSubtotal) : (subtotal - updatedSubtotal)}

							</span>
						</p>
						<p className="d-flex whiteText">
							<span style={{ width: "60%", textAlign: "right" }}>
								Sales Tax:
							</span>
							<span style={{ width: "40%", textAlign: "right" }}>
								$ {giftItem.discount_value > 0 ? parseFloat(subtotalWithDiscountProduct * 0.0888).toFixed(2) : salesTax}
							</span>
						</p>
						<p className="d-flex whiteText">
							<span style={{ width: "60%", textAlign: "right" }}>Total:</span>
							<span style={{ width: "40%", textAlign: "right" }}>
								$ {subtotalWithDiscountProduct === 0 ?
									(redeemedItem.length ? parseFloat((total - updatedSubtotal + (total - updatedSubtotal < 50 ? 10 : delivery)).toFixed(2)) : total - discount)
									: subtotalWithDiscountProduct + parseFloat(parseFloat(subtotalWithDiscountProduct * 0.0888).toFixed(2)) + tips + (subtotalWithDiscountProduct < 50 ? 10 : 0)}
							</span>
						</p>
					</div>
					<div>
						<h5
							style={{
								backgroundColor: "#094100",
								color: "white",
								padding: "10px",
							}}
						>
							SHIPPING INFORMATION
						</h5>
						<Form>
							<Row className="mb-3 whiteText">
								<Form.Group as={Col} controlId="formGridFirstName">
									<Form.Label>First Name</Form.Label>
									<Form.Control
										type="text"
										placeholder="First Name"
										value={firstName}
										onChange={(e) => setFirstName(e.target.value)}
									/>
								</Form.Group>
								<Form.Group as={Col} controlId="formGridLastName">
									<Form.Label>Last Name</Form.Label>
									<Form.Control
										type="text"
										placeholder="Last Name"
										value={lastName}
										onChange={(e) => setLastName(e.target.value)}
									/>
								</Form.Group>
							</Row>

							<Row className="mb-3 whiteText">
								<Form.Group as={Col} controlId="formGridEmail">
									<Form.Label>Email</Form.Label>
									<Form.Control
										type="email"
										placeholder="Enter email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</Form.Group>
							</Row>

							<Form.Group
								className="mb-3 whiteText"
								controlId="formGridAddress1"
							>
								<Form.Label>Address</Form.Label>
								<Form.Control
									placeholder="Address"
									value={address}
									onChange={(e) => setAddress(e.target.value)}
								/>
							</Form.Group>

							<Row className="mb-3 whiteText">
								<Form.Group as={Col} controlId="formGridCity">
									<Form.Label>City</Form.Label>
									<Form.Control
										placeholder="City"
										value={city}
										onChange={(e) => setCity(e.target.value)}
									/>
								</Form.Group>

								<Form.Group as={Col} controlId="formGridState">
									<Form.Label>State</Form.Label>
									<Form.Control
										placeholder="State"
										value={state}
										onChange={(e) => setState(e.target.value)}
									/>
								</Form.Group>
							</Row>
							<Row className="whiteText">
								<Form.Group as={Col} controlId="formGridZip">
									<Form.Label>Zip</Form.Label>
									<Form.Control
										placeholder="Zip Code"
										value={zipcode}
										onChange={(e) => setZipcode(e.target.value)}
									/>
								</Form.Group>
								<Form.Group as={Col} controlId="formGridZip">
									<Form.Label>Phone</Form.Label>
									<Form.Control
										placeholder="Phone"
										value={phone}
										onChange={(e) => setPhone(e.target.value)}
									/>
								</Form.Group>
							</Row>
							<Row className="mt-3 whiteText">
								<Form.Group as={Col} controlId="formGridZip">
									<Form.Label>Delivery Instruction</Form.Label>
									<Form.Control
										placeholder="Example: Front Door, Mail Box etc..."
										value={instruction}
										onChange={(e) => setInstruction(e.target.value)}
									/>
								</Form.Group>
							</Row>

							<div>
								<Button
									color="info"
									variant="contained"
									sx={{
										margin: "55px 0  ", backgroundColor: "#094100",
										'&:disabled': { // Add a separate style for the disabled state
											backgroundColor: '#ccc', // or any other style you prefer
										},

									}}
									onClick={() => handleSubmit(orderItems)}
									disabled={inPrograss}
								>
									{!inPrograss ? <>Confirm</> : <>In Prograss  <CircularProgress color="success" sx={{ width: '15px !important', height: '15px !important', marginLeft: '5px' }} /></>}
								</Button>
							</div>
						</Form>
					</div>
				</div>
			) : (
				<p style={{ color: 'white', margin: '10px 0' }}>Currently no items to checkout ... </p>
			)}
		</div>
	);
}
