import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function ConfirmationPopup(props) {
	const { openConFirmationPopup, setOpenConfirmationPopup } = props;
	const handleClose = () => setOpenConfirmationPopup(false);

	useEffect(() => {
		if (openConFirmationPopup) {
			setOpenConfirmationPopup(true);
			setTimeout(() => {
				setOpenConfirmationPopup(false);
			}, 2100);
		}
	}, [openConFirmationPopup]);

	return (
		<div>
			<Modal
				show={openConFirmationPopup}
				onHide={handleClose}
				className="confirmationPopup"
				dialogClassName="transparent-modal"
			>
				<Modal
					show={openConFirmationPopup}
					onHide={handleClose}
					className="confirmationPopup"
					dialogClassName="transparent-modal"
				>
					<Modal.Body className="svg-container">
						<svg className="centered-svg">
							<circle
								className="path Circle"
								fill="none"
								stroke="white"
								strokeWidth="6"
								strokeMiterlimit="10"
								cx="65.1"
								cy="65.1"
								r="62.1"
							/>

							<polyline
								className="path Check"
								fill="none"
								stroke="white"
								strokeWidth="6"
								strokeLinecap="round"
								strokeMiterlimit="10"
								points="100.2,40.2 51.5,88.8 29.8,67.5 "
							></polyline>
						</svg>
					</Modal.Body>
				</Modal>
			</Modal>
		</div>
	);
}
