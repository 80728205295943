import React, { useState } from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { Chip, Paper, IconButton, Icon } from "@mui/material";
import axios from "axios";
import { url } from "../url";
import ConfirmationPopup from "./ConfirmationPopup";
import { useNavigate } from "react-router-dom";
// import io from "socket.io-client";
// const socket = io.connect("http://localhost:8080");

export default function ContactByOrder(props) {
	const { show, hideMessage, orderNumber, email, name, userId } = props;
	const [openConFirmationPopup, setOpenConfirmationPopup] = useState(false);
	const navigate = useNavigate();

	const [message, setMessage] = useState("");

	const sendMessage = () => {
		// socket.emit(`message_admin`, {
		// 	send_from: userId,
		// 	send_to: 3,
		// });
		axios
			.post(url + "/contact_by_order", {
				email: email,
				message: message,
				orderNumber: orderNumber,
				name: name,
			})
			.then((response) => {
				setOpenConfirmationPopup(true);
				setTimeout(() => {
					navigate("/shop");
				}, 2100);
			});
	};
	return (
		<div>
			<ConfirmationPopup
				openConFirmationPopup={openConFirmationPopup}
				setOpenConfirmationPopup={setOpenConfirmationPopup}
			/>
			<Modal show={show} onHide={hideMessage}>
				<Modal.Header closeButton>
					<Modal.Title>Question for #{orderNumber.toUpperCase()}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label style={{ float: "left" }}>Message</Form.Label>
							<Form.Control
								as="textarea"
								rows={5}
								name="message"
								placeholder="message"
								value={message}
								onChange={(e) => {
									setMessage(e.target.value);
								}}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={hideMessage}>
						Close
					</Button>
					<Button variant="primary" onClick={sendMessage}>
						Send
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
