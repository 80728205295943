import { createContext, useState, useEffect } from "react";
import axios from "axios";
import { url } from "./url";
export const DashContext = createContext();

export function DashContextProvider({ children }) {
    const [income, setIncome] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getIncome = async () => {
        setIsLoading(true)
        try {
            const result = await axios.get(url + '/get_income');
            setIncome(result.data)
        } catch (error) {
            console.error("Error fetching product data:", error);
            return undefined;
        }
        setIsLoading(false)
    }

    return (
        <DashContext.Provider
            value={{
                getIncome,
                income,
                isLoading
            }}
        >
            {children}
        </DashContext.Provider>
    )
}
export default DashContextProvider;
