import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { url } from "../url";
import { Chip, Paper, Button, IconButton, Icon } from "@mui/material";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import AlertPopUp from "./AlertPopUp";

export default function CreateProduct() {
	const navigate = useNavigate();
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [price, setPrice] = useState(0);
	const [category, setCategory] = useState("");
	const [sale, setSale] = useState(false);
	const [storeOne, setStoreOne] = useState(0);
	const [storeTwo, setStoreTwo] = useState(0);
	const [storeThree, setStoreThree] = useState(0);
	const [file, setFile] = useState(null);
	const [tag, setTag] = useState("");
	const [chipData, setChipData] = useState([]);
	const [pointsNeeded, setPointsNeeded] = useState(0);
	const [featureRedeem, setFeatureRedeem] = useState(0);
	// =============================================================
	const [flavors, setFlavor] = useState([]);
	const [flavorTitle, setflavorTitle] = useState("");
	const [count, setCount] = useState(0);
	const [soldOut, setSoldOut] = useState(false);

	const [open, setOpen] = useState(false);
	const [color, setColor] = useState("");
	const [message, setMessage] = useState("");

	const handleClick = (txt, color) => {
		setOpen(true);
		setMessage(txt);
		setColor(color);
	};

	const createProduct = async function (e) {
		if (!file) {
			handleClick("File required !", "error");
			return;
		} else if (!category) {
			handleClick("Category is required !", "error");
		} else {
			let updatedCategory = category;
			const formData = new FormData();

			updatedCategory = updatedCategory.trimEnd();
			formData.append("title", title);
			formData.append("description", description);
			formData.append("price", price);
			formData.append("category", updatedCategory.toLowerCase());
			formData.append("sale", sale);
			formData.append("storeOne", storeOne);
			formData.append("storeTwo", storeTwo);
			formData.append("storeThree", storeThree);
			formData.append("file", file);
			formData.append("options", JSON.stringify(chipData));
			formData.append("pointsNeeded", pointsNeeded);
			formData.append("featureRedeem", featureRedeem);
			axios.post(url + "/create_product", formData).then(result => {
				if (!result.data) {
					handleClick("error", 'error');
				} else {
					handleClick("Product Created redirect in 2 seconds !");
					setTimeout(() => {
						navigate("/shop");
					}, 1000);
				}
			});

		}
	};
	const handleCountChange = (e, index) => {
		const updatedChipData = [...chipData];
		updatedChipData[index].count = e.target.value;
		setChipData(updatedChipData);
	};

	const addTag = (e) => {
		setChipData([
			...chipData,
			{ key: chipData.length + 1, label: tag, count: count },
		]);
		setTag("");
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			addTag();
			e.target.value = "";
		}
	};

	const handleDelete = (chipToDelete) => () => {
		setChipData((chips) =>
			chips.filter((chip) => chip.key !== chipToDelete.key),
		);
	};

	const [imageName, setImageName] = useState("");

	const handleInputChange = (event) => {
		const inputImage = event.target.files[0];
		setImageName(inputImage.name);
		setFile(inputImage);
	};

	return (
		<div className="container">
			<Form className="whiteText">
				<Row className="mb-3">
					<Form.Group as={Col} controlId="formGridTitle">
						<Form.Label className="">Title</Form.Label>
						<Form.Control
							type="text"
							placeholder="Title"
							onChange={(e) => setTitle(e.target.value)}
						/>
					</Form.Group>
					<Form.Group as={Col} controlId="formGridDescription">
						<Form.Label className="">Description</Form.Label>
						<Form.Control
							type="text"
							placeholder="Description"
							onChange={(e) => setDescription(e.target.value)}
						/>
					</Form.Group>
				</Row>
				<Row className="mb-3">
					<Form.Group as={Col} controlId="formGridPrice">
						<Form.Label className="">Price</Form.Label>
						<Form.Control
							type="text"
							placeholder="Price"
							onChange={(e) => setPrice(e.target.value)}
						/>
					</Form.Group>
					<Form.Group as={Col} controlId="formGridCategory">
						<Form.Label className="">Category</Form.Label>
						<Form.Control
							type="text"
							placeholder="Category"
							onChange={(e) => setCategory(e.target.value)}
						/>
					</Form.Group>
				</Row>
				<Row className="mb-3">
					<Form.Group as={Col} controlId="formGridSale">
						<Form.Label className="">Sale and Featured ?</Form.Label>
						<Form.Select
							aria-label="Default select example"
							onChange={(e) => setSale(e.target.value)}
						>
							<option value="0">False</option>
							<option value="1">True</option>
						</Form.Select>
					</Form.Group>
					<Form.Group as={Col} controlId="formGridSale">
						<Form.Label className="">Featured Redeem ?</Form.Label>
						<Form.Select
							aria-label="Default select example"
							onChange={(e) => setFeatureRedeem(e.target.value)}
						>
							<option value="0">False</option>
							<option value="1">True</option>
						</Form.Select>
					</Form.Group>
				</Row>

				<Row className="mb-3">
					<Form.Group as={Col} controlId="formGridStoreOne">
						<Form.Label className="">Points needed to redeem</Form.Label>
						<Form.Control
							type="text"
							placeholder="Points needed"
							onChange={(e) => setPointsNeeded(e.target.value)}
						/>
					</Form.Group>
				</Row>

				{/*<Row className="mb-3">
					<Form.Group as={Col} controlId="formGridStoreOne">
						<Form.Label className="">Store One (unit)</Form.Label>
						<Form.Control
							type="text"
							placeholder="StoreOne"
							onChange={(e) => setStoreOne(e.target.value)}
							disabled
						/>
					</Form.Group>
					<Form.Group as={Col} controlId="formGridStoreTwo">
						<Form.Label className="">StoreTwo (unit)</Form.Label>
						<Form.Control
							type="text"
							placeholder="StoreTwo"
							onChange={(e) => setStoreTwo(e.target.value)}
							disabled
						/>
					</Form.Group>
					<Form.Group as={Col} controlId="formGridStoreThree">
						<Form.Label className="">Store Three (unit)</Form.Label>
						<Form.Control
							type="text"
							placeholder="StoreThree"
							onChange={(e) => setStoreThree(e.target.value)}
							disabled
						/>
					</Form.Group>
				</Row>
	*/}
				<Row>
					<Form.Group
						as={Col}
						controlId="formGridCategory"
						className="col-8-md col-12-sm mb-3"
						style={{ margin: "auto" }}
					>
						<Form.Label className="">Item Options</Form.Label>
						<div className="d-flex">
							<Form.Control
								style={{ marginRight: "25px", width: "100%" }}
								type="text"
								placeholder="Item Options"
								value={tag}
								onChange={(e) => setTag(e.target.value)}
								onKeyDown={handleKeyDown}
							/>
							<Button onClick={addTag} variant="contained" color="success">
								+
							</Button>
						</div>
						<Paper
							sx={{
								display: "flex",
								justifyContent: "center",
								flexWrap: "wrap",
								listStyle: "none",
								p: 3,
								m: 0,
								marginTop: "30px",
							}}
							component="ul"
						>
							{chipData.map((data) => {
								let icon;

								if (data.label === "React") {
									icon = <TagFacesIcon />;
								}

								return (
									<div key={data.key}>
										<Chip
											icon={icon}
											label={data.label}
											onDelete={handleDelete(data)}
											sx={{ background: "", color: "", m: 1 }}
										/>
									</div>
								);
							})}
						</Paper>
					</Form.Group>
				</Row>
				<Row>
					<Form.Group>
						{chipData?.map((data, index) => {
							return (
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										marginTop: "15px",
									}}
									key={{ index }}
								>
									<Form.Label className="">
										{data.label.toUpperCase()}
									</Form.Label>

									<input
										type="number"
										value={data.count}
										onChange={(e) => handleCountChange(e, index)}
										style={{ width: "80px" }}
									/>
								</div>
							);
						})}
					</Form.Group>
				</Row>
				<Row className="mb-3">
					<IconButton
						color="primary"
						aria-label="upload picture"
						component="label"
						sx={{ width: "100px" }}
					>
						<input
							hidden
							accept="image/*"
							type="file"
							onChange={handleInputChange}
						/>
						<PhotoCamera sx={{ fontSize: "50px" }} />
					</IconButton>
					<span id="imageName">{imageName}</span>
				</Row>

				<Button
					variant="contained"
					sx={{ marginTop: "25px" }}
					onClick={createProduct}
				>
					Create
				</Button>
			</Form>

			<AlertPopUp
				open={open}
				setOpen={setOpen}
				message={message}
				handleClick={handleClick}
				color={color}
			/>
		</div>
	);
}
