import React, { useState, useEffect, useContext } from "react";
import { Drawer, ThemeProvider } from "@mui/material";
import CartItem from "./CartItem";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import AlertPopUp from "./AlertPopUp";
import { createTheme } from "@mui/material/styles";
import { ProductContext } from "../ProductContext";
import { useLocation } from 'react-router-dom';

export default function CartPopUp(props) {
	const { cartOpen, handleClose, currentUser } = props;
	const navigate = useNavigate();
	const {
		cartItem,
		addToCart,
		removeFromCart,
		subtotal,
		salesTax,
		delivery,
		getTotal,
		total,
	} = useContext(ProductContext);

	const [open, setOpen] = useState(false);
	const [color, setColor] = useState("");
	const [message, setMessage] = useState("");
	const [disabled, setDisabled] = useState(false)
	const location = useLocation();
	const currentPath = location.pathname;

	const handleClick = (txt, color) => {
		setOpen(true);
		setMessage(txt);
		setColor(color);
	};

	const submitOrder = () => {
		setDisabled(true)
		navigate("/checkout");
		handleClose();
	};

	const theme = createTheme({
		components: {
			MuiPaper: {
				styleOverrides: {
					root: {
						backgroundColor: "#094100",
					},
				},
			},
		},
	});

	useEffect(() => {
		getTotal();
	}, [currentUser, subtotal, salesTax, getTotal]);

	return (
		<ThemeProvider theme={theme}>
			<Drawer
				anchor="right"
				open={cartOpen}
				onClose={handleClose}
				className="drawerContainer"
			>
				<AlertPopUp
					open={open}
					setOpen={setOpen}
					message={message}
					handleClick={handleClick}
					color={color}
				/>
				<div className="cartPopUp">
					<div style={{ width: "100%", textAlign: "right" }}>
						<CloseIcon onClick={handleClose} sx={{ color: "white" }} />
					</div>
					<h3
						style={{ textAlign: "center", margin: "15px 15px", color: "white" }}
					>
						Shopping Cart
					</h3>
					{cartItem == null || cartItem.length === 0 ? (
						<div style={{ width: "100%" }}>
							<p
								style={{
									textAlign: "center",
									alignItems: "center !important",
									color: "white",
								}}
							>
								Cart is empty, return to{" "}
								<Button
									variant="contained"
									sx={{
										color: "white",
										backgroundColor: "black",
										marginLeft: "25px",
									}}
									onClick={() => {
										navigate("/shop");
									}}
								>
									SHOP
								</Button>
							</p>
						</div>
					) : (
						cartItem &&
						cartItem.map((el, index) => {
							return (
								<CartItem
									key={index}
									item={el}
									addToCart={addToCart}
									removeFromCart={removeFromCart}
									disabled={disabled}
									currentPath={currentPath}
								/>
							);
						})
					)}
					{cartItem == null || cartItem.length === 0 ? (
						<p></p>
					) : (
						<div className="d-flex w-100 justify-content-between mt-5">
							<div></div>
							<div></div>
							<div></div>
							<div>
								<h6 className="whiteText">Subtotal</h6>
								<h6 style={{ color: "white" }}>Delivery : </h6>
								<h6 className="whiteText">Sales Tax : </h6>
								<h6 style={{ alignSelf: "end", color: "white" }}>Total :</h6>
							</div>
							<div>
								<div style={{ alignSelf: "end", color: "white" }}>
									<h6>${subtotal}</h6>
									<h6 style={{ color: "white" }}>
										${delivery === 0 ? "FREE" : delivery}
									</h6>
									<h6>${salesTax}</h6>
									<h6>${total}</h6>
								</div>
							</div>
						</div>
					)}
				</div>

				{currentUser?.success ? (
					<div className="submitButton">
						<Button
							variant="contained"
							disabled={
								cartItem == null || cartItem.length === 0 ? true : false
							}
							onClick={() => submitOrder()}
							sx={{ color: "white !important", backgroundColor: "black" }}
						>
							Check Out
						</Button>
					</div>
				) : (
					<div className="submitButton">
						<Button
							sx={{
								width: "60%",
								textAlign: "center",
								color: "white",
								backgroundColor: "black",
							}}
							onClick={() => {
								handleClose();
								navigate("/loginForm");
							}}
						>
							Login Here
						</Button>
					</div>
				)}
			</Drawer>
		</ThemeProvider>
	);
}
