import React, { useEffect, useState } from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { url } from "../url";
import moment from "moment";
import { Button } from "@mui/material";
import AlertPopUp from "./AlertPopUp";
import Modal from "react-bootstrap/Modal";
import FormSelect from "./FormSelect";

export default function CreatePromoCode() {
	const [promocodes, setPromocodes] = useState([]);
	const [code, setCode] = useState("");
	const [description, setDescription] = useState("");
	const [open, setOpen] = useState(false);
	const [color, setColor] = useState("");
	const [message, setMessage] = useState("");
	const [products, setProducts] = useState();
	const [selectedProduct, setSelectedProduct] = useState(0);
	const [updateProductId, setUpdateProductId] = useState(0);
	const [show, setShow] = useState(false);
	const [type, setType] = useState("")
	const [discountValue, setDiscountValue] = useState(0)

	const promoType = ["Gift Item", 'Discount On Order', 'Discount On Item']

	const handleClose = () => setShow(false);
	const handleShow = (id) => {
		setUpdateProductId(id);
		setShow(true);
	};

	const formattedDate = (date) => moment.utc(date).format("YYYY-MM-DD");
	const createPromo = async () => {
		if (code.length === 0 || description.length === 0) { return handleClick('fields are required', 'error') }
		if (discountValue >= 1) { return handleClick('Discount value must less than 1', 'error') }
		await axios
			.post(url + "/create_promocode", { code, description, selectedProduct, discountValue })
			.then((response) => {
				if (response.data.message !== "Promo code already exists") {
					handleClick(response.data.message, "success");
				} else {
					handleClick(response.data.message, "error");
				}
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			});
	};

	const handleClick = (txt, color) => {
		setOpen(true);
		setMessage(txt);
		setColor(color);
	};

	const deleteCode = async (id) => {
		await axios
			.delete(url + "/delete_promocode", { data: { id } })
			.then((response) => {
				if (response.data.message !== "error, pls contact admin") {
					handleClick(response.data.message, "success");
				} else {
					handleClick(response.data.message, "error");
				}
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			});
	};

	const updateCode = async (productId, id, description) => {
		await axios
			.put(url + "/update_promocode", {
				id: id,
				productId: productId,
				description: description,
			})
			.then((response) => {
				console.log(response.data.message);
				handleClick(response.data.message);
			});
	};

	const getPromocodes = async () => {
		await axios.get(url + "/all_promocodes").then((response) => {
			setPromocodes(response.data);
		});
	};

	const getProducts = async () => {
		axios.get(url + "/all_products").then((response) => {
			setProducts(response.data);
		});
	};

	useEffect(() => {
		getProducts();
		getPromocodes();
	}, []);
	return (
		<div className="createPromo whiteText">
			<AlertPopUp
				open={open}
				setOpen={setOpen}
				message={message}
				handleClick={handleClick}
				color={color}
			/>
			<table
				key={code.promoCode}
				style={{ margin: "auto", width: "70%", marginBottom: "50px" }}
			>
				<thead>
					<tr
						style={{
							backgroundColor: "black",
							color: "white",
							borderRadius: "15px",
						}}
					>
						<th>Code</th>
						<th>Selected Item</th>
						<th>Description</th>
						<th>Created On</th>
					</tr>
				</thead>
				<tbody>
					{promocodes.length ? (
						promocodes?.map((code) => {
							return (
								<tr key={code.promoCode}>
									<td>{code.promoCode}</td>
									<td>{code.product_title}</td>
									<td style={{ textOverflow: "ellipsis" }}>
										{code.description}
									</td>
									<td>
										{formattedDate(code.updated)}{" "}
										<button
											style={{
												border: "none",
												backgroundColor: "red",
												color: "white",
												width: "25px",
												height: "25px",
												margin: "5px 5px",
												borderRadius: "5px",
											}}
											onClick={() => {
												deleteCode(code.id);
											}}
										>
											<p style={{ textAlign: "center", margin: "0" }}>X</p>
										</button>
									</td>
								</tr>
							);
						})
					) : (
						<tr>
							<td>No Code Found.</td>
						</tr>
					)}
				</tbody>
			</table>
			<Form style={{ width: "70%", margin: "auto" }}>
				<Form.Group className="mb-3" controlId="promocode">
					<Form.Label>Promo Code</Form.Label>
					<Form.Control
						type="text"
						placeholder="promocode"
						onChange={(e) => setCode(e.target.value)}
					/>
				</Form.Group>

				<Form.Group className="mb-3" controlId="promocode">
					<Form.Label>Select Promo type</Form.Label>
					<Form.Select
						aria-label="Default select example"
						defaultValue="option"
						onChange={(e) => setType(e.target.value)}
					>
						{promoType?.map((type, index) => {
							return (
								<option value={type} key={index}>
									{type}
								</option>
							);
						})}
					</Form.Select>
				</Form.Group>

				{
					type === 'Gift Item' || type.length === 0 ?
						<Form.Group className="mb-3" controlId="promocode">
							<Form.Label>
								Gift Item (
								<span style={{ color: "red" }}>
									Only use it if code meant to gift item!
								</span>
								)
							</Form.Label>
							<Form.Select
								aria-label="Default select example"
								defaultValue="option"
								onChange={(e) => setSelectedProduct(e.target.value)}
							>
								<option defaultValue="select a product" >
									--
								</option>
								{products?.map((product) => {
									return (
										<option value={product.id} key={product.id}>
											{product.title}
										</option>
									);
								})}
							</Form.Select>
						</Form.Group>
						:
						<></>
				}

				{
					type === 'Discount On Order' ?
						<Form.Group className="mb-3" controlId="promocode">
							<Form.Label>Discount value (0.9 = 10% off)</Form.Label>
							<Form.Control
								type="number"
								placeholder="Discount Value"
								step={0.01}
								onChange={(e) => setDiscountValue(Number(e.target.value))}
							/>
						</Form.Group>
						:
						<></>
				}


				{
					type === 'Discount On Item' ?
						<Form.Group className="mb-3" controlId="promocode">
							<Form.Label>
								Discounted Item
							</Form.Label>
							<Form.Select
								aria-label="Default select example"
								defaultValue="option"
								onChange={(e) => setSelectedProduct(e.target.value)}
							>
								<option defaultValue="select a product" >
									--
								</option>
								{products?.map((product) => {
									return (
										<option value={product.id} key={product.id}>
											{product.title}
										</option>
									);
								})}
							</Form.Select>
							<Form.Label>Discount value (0.9 = 10% off)</Form.Label>
							<Form.Control
								type="number"
								placeholder="Discount Value"
								step={0.01}
								onChange={(e) => setDiscountValue(Number(e.target.value))}
							/>
						</Form.Group>
						:
						<></>
				}

				<Form.Group
					className="mb-3"
					controlId="description"
					onChange={(e) => setDescription(e.target.value)}
				>
					<Form.Label>Description</Form.Label>
					<Form.Control as="textarea" rows={3} />
				</Form.Group>
				<Button variant="contained" onClick={createPromo}>
					Create Code
				</Button>
			</Form>
		</div>
	);
}
